import React, { useState, useEffect } from 'react';
import './ToTopButton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faArrowUp } from '@fortawesome/free-solid-svg-icons';

library.add(faArrowUp);
function ToTopButton() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollDistance = window.pageYOffset;
      const footer = document.querySelector('footer');
      const footerDistance = footer.offsetTop - window.innerHeight;
      const buffer = 20;
      if (scrollDistance > buffer && scrollDistance < footerDistance - buffer) {
        setShowButton(true);
      } else {
        setShowButton(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleClick = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  };

  return (
    <>
      {showButton && (
        <button className="to-top-button" onClick={handleClick}>
          <FontAwesomeIcon icon={faArrowUp} style={{ color: 'white' }} />
        </button>
      )}
    </>
  );
}

export default ToTopButton;
