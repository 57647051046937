import React, { useRef, useEffect, useState } from 'react';
import './Scrollable.css';
function Scrollable({ children, arrowPosition, heightWidth }) {
  const scrollRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  useEffect(() => {
    const handleMouseDown = (event) => {
      setDragging(true);
      setStartX(event.pageX - scrollRef.current.offsetLeft);
      setScrollLeft(scrollRef.current.scrollLeft);
    };

    const handleMouseUp = () => {
      setDragging(false);
    };

    const handleMouseMove = (event) => {
      if (!dragging) return;
      event.preventDefault();
      const x = event.pageX - scrollRef.current.offsetLeft;
      const walk = (x - startX) * 1.5;
      scrollRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleScroll = () => {
      const containerWidth = scrollRef.current.offsetWidth;
      const contentWidth = scrollRef.current.scrollWidth;
      const scrollPosition = scrollRef.current.scrollLeft;
      setShowLeftArrow(scrollPosition > 0);
      setShowRightArrow(containerWidth + scrollPosition < contentWidth);
    };

    scrollRef.current.addEventListener('mousedown', handleMouseDown);
    scrollRef.current.addEventListener('mouseup', handleMouseUp);
    scrollRef.current.addEventListener('mouseleave', handleMouseUp);
    scrollRef.current.addEventListener('mousemove', handleMouseMove);
    scrollRef.current.addEventListener('scroll', handleScroll);

    return () => {
      try {
        scrollRef.current.removeEventListener('mousedown', handleMouseDown);
        scrollRef.current.removeEventListener('mouseup', handleMouseUp);
        scrollRef.current.removeEventListener('mouseleave', handleMouseUp);
        scrollRef.current.removeEventListener('mousemove', handleMouseMove);
        scrollRef.current.removeEventListener('scroll', handleScroll);
      } catch (error) {
        console.log("cleaning up..");
      }

    };
  }, [dragging, startX, scrollLeft]);

  const handleLeftArrowClick = () => {
    scrollRef.current.scrollLeft -= 150;
  };

  const handleRightArrowClick = () => {
    scrollRef.current.scrollLeft += 150;
  };
  return (
    <div className="scrollable-container">
      <div className="scrollable" style={{ display: 'flex', overflowX: 'scroll', scrollBehavior: 'smooth' }} ref={scrollRef}>
        {children}
        <style jsx>{`
    .scrollable > * {
      flex: 0 0 auto;
      margin-right: 10px;
      overflow-y: hidden;
      overflow-x: hidden;
      white-space: normal;
    }
  `}</style>
      </div>

      {showLeftArrow && (
        <div className="scroll-arrow scroll-arrow-left" style={{ top: arrowPosition }} onClick={handleLeftArrowClick}>
          &lt;
        </div>
      )}
      {showRightArrow && (
        <div className="scroll-arrow scroll-arrow-right" style={{ top: arrowPosition }} onClick={handleRightArrowClick}>
          &gt;
        </div>
      )}
    </div>
  );
}

export default Scrollable;