import './HomePage.css';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ToTopButton from "../components/ToTopButton.js";
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from '../images/logo_new.png';
import Footer from '../components/Footer';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import Header from '../components/Header';

function Impressum({ data }) {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <div className="App">
      <Header data={data} hideMenu={true} />
      <body>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <h1>Impressum</h1>
          <div style={{ display: 'flex', justifyContent: 'center' }}><img src={logo} width={150} alt="logo" /></div>
          <div style={{ display: 'flex', justifyContent: "center" }}>
            <a href="https://www.facebook.com/pages/Eli's%20D%C3%B6ner/622389974846130/" target="_blank" rel="noopener noreferrer" ><FontAwesomeIcon icon={faFacebookF} fontSize={25} color={"#282c30"} style={{ padding: 10 }} /></a>
            <a href="https://www.instagram.com/elis.doner/" target="_blank" rel="noopener noreferrer"  ><FontAwesomeIcon icon={faInstagram} fontSize={25} color={"#282c30"} style={{ padding: 10 }} /></a>
            <a href="https://goo.gl/maps/ar9M8YqB7fg7z8BT6" target="_blank" rel="noopener noreferrer"  ><FontAwesomeIcon icon={faLocationDot} fontSize={25} color={"#282c30"} style={{ padding: 10 }} /></a>
          </div>
          <h2>Angaben gemäß § 5 TMG</h2>
          <p>Bilal Özhan<br />
            Salzburger Straße 1 (ELI)<br />
            8940 Liezen</p>
          <h2>Kontakt</h2>
          <p>Telefon: <a href='callto:+43361224179' target="_blank" rel="noopener noreferrer">03612 24179</a><br />
            E-Mail: <a href='mailto:office@elis-döner.at' target="_blank" rel="noopener noreferrer">office@elis-döner.at</a></p>
          <h2>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV</h2>
          <p>Bilal Özhan<br />
            Salzburger Straße 1 (ELI)<br />
            8940 Liezen</p>
          <h2>Bildnachweis</h2>
          <p>Pexels<br />
            Unsplash<br />
            Pixabay</p>
          <h2>Design und Umsetzung</h2>
          <p>Robert Gjorgjiev<br />
            <a href='mailto:robert.gjorgjiev@hotmail.com' target="_blank" rel="noopener noreferrer">robert.gjorgjiev@hotmail.com</a><br /><br />
            <a href='https://www.facebook.com/robgjo/' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: '#282c30' }}><FontAwesomeIcon icon={faFacebookF} fontSize={20} /></a>
            <a href='https://instagram.com/_robert_gj?igshid=YmMyMTA2M2Y=' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: '#282c30' }}><FontAwesomeIcon icon={faInstagram} fontSize={20} /></a>
            <a href='https://www.linkedin.com/in/robert-gjorgjiev-01716b1b6' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: '#282c30' }}><FontAwesomeIcon icon={faLinkedinIn} fontSize={20} /></a>
          </p>
          <ToTopButton></ToTopButton>
        </div>
      </body>
      <Footer></Footer>
    </div>
  );
}

export default Impressum;