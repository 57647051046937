import React from 'react';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function Loading() {
  return (
    <div className="loading">
      <FontAwesomeIcon icon={faSpinner} />Loading...
    </div>
  );
}

export default Loading;