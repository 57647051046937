import React from 'react';
import './MenuEntryCard.css';

function MenuEntryCard({ item }) {

  var price = <div className="menuCardPrice">{item.price + " €"}</div>;
  var priceParty;
  if (item.priceParty) {
    price =
    <div className='menuCardPriceColumn'>
      <div className="menuCardPricePartySingle">{"Single"}</div>
      <div className="menuCardPrice">{item.price + " €"}</div>
    </div>

    priceParty =  <div className='menuCardPriceColumn'>
    <div className="menuCardPricePartySingle">{"Party"}</div>
    <div className="menuCardPrice">{item.priceParty + " €"}</div>
  </div>
  }

  return (
    <div className='menuCardWrapper'>

      <div className="menuCardEntry">
        <div className='menuCardEntryColumn'>
          <div className="menuCardTitle">{item.title}  </div>
          <div className="menuCardSubtitle"> {item.description}</div>
          <div className="menuCardAllergenic">{item.allergenic}</div>
        </div>
        {price}
        {priceParty}
      </div>
    </div>
  );
}

export default MenuEntryCard;