import './HomePage.css';
import React from 'react';
import MenuEntryCard from "../components/MenuEntryCard.js";
import ToTopButton from "../components/ToTopButton.js";
import Loading from "../components/Loading.js"
import Footer from '../components/Footer';
import Header from '../components/Header';

function HomePage({ menu ,  loading }) {
  return (
    <div className="App">
      <Header menu={menu} hideMenu={false} />
      <body>
        {loading ? (
          <div className='LoadingWrapper'><Loading /></div>
        ) : <div>
          <ToTopButton></ToTopButton>
          <div>{menu && menu.map((menuList, i) => (
            <div className="MenuTitleWrapper">
              <div className='MenuTitle' id={menuList.title} >
                <img src={menuList.imageBanner} alt={menuList.title} />
              </div>
              {
                menuList.items.map((menuEntry) => (
                  <MenuEntryCard item={menuEntry} ></MenuEntryCard>
                ))
              }</div>
          ))}</div></div>}
      </body>
      <Footer></Footer>
    </div>
  );
}

export default HomePage;