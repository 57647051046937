import React from 'react';
import { faFacebookF, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./Footer.css";
import { Link } from 'react-router-dom';

function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="FooterWrapper">
      <div className='footer'>
        <div className="left">
          <div className='FooterMenuPoints' >
            
          <div style={{ padding: 3 }}><Link to="/impressum" style={{ textDecoration: "none" }}><a style={{ color: "white" }}>Impressum</a></Link></div>
          <div style={{ padding: 3 }}><Link to="/allergene" style={{ textDecoration: "none" }}><a style={{ color: "white" }}>Allergene</a></Link></div>
          </div>
          
        <div style={{ fontSize: 12,  padding: 3}}>© {currentYear}</div>
        </div>
        <div className='middle' style={{ fontSize: 14 }}>
          <div>Öffnungszeiten<br/>Mo-Fr: 10:00 - 19:00<br/>Sa: 10:00 - 18:30</div>
        </div>
        <div className="right">
          Designed by <br/>robgjo
          <div style={{paddingTop:5}}>
            <a href='https://www.facebook.com/robgjo/' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: 'white' }}><FontAwesomeIcon fontSize={15} icon={faFacebookF} /></a>
            <a href='https://instagram.com/_robert_gj?igshid=YmMyMTA2M2Y=' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: 'white' }}><FontAwesomeIcon fontSize={15} icon={faInstagram} /></a>
            <a href='https://www.linkedin.com/in/robert-gjorgjiev-01716b1b6' target="_blank" rel="noopener noreferrer" style={{ padding: 5, color: 'white' }}><FontAwesomeIcon fontSize={15} icon={faLinkedinIn} /></a>
          </div>

        </div></div>
    </footer>

  );
}

export default Footer;