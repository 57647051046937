import React from 'react';

function ScrollableEntry({ item, heightWidth }) {
  function handleClick() {
    const element = document.getElementById(item.title);
    const topOffset = element.getBoundingClientRect().top;
    const scrollPosition = window.pageYOffset + topOffset - heightWidth - 80;
    window.scrollTo({ top: scrollPosition, behavior: "smooth" });
  }

  return (
    <div onClick={handleClick}>
      <img width={heightWidth} height={heightWidth} src={item.image} alt={item.title} />
    </div>
  );
}

export default ScrollableEntry;