import React, { useState, useEffect } from 'react';
import './Menu.css';
import ScrollableEntry from './ScrollableEntry';
import Scrollable from './Scrollable';

export const Menu = ({ menu }) => {
  const [heightWidth, setHeightWidth] = useState(150);
  const [arrowPosition, setArrowPosition] = useState();
  const [deviceType, setDeviceType] = useState('');

  useEffect(() => {
    //get device type 
    const { userAgent } = window.navigator;

    if (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
      setDeviceType('mobile');
      setArrowPosition(150)
    } else {
      setDeviceType('desktop');
      setArrowPosition(75);
    }
  }, []);

  useEffect(() => {
    function handleScroll() {
      if (window.scrollY > 0) {
        if (deviceType == "mobile") {
          setHeightWidth(100);
          setArrowPosition(125);
        }
      } else {
        if (deviceType == "mobile") {
          setHeightWidth(150);
          setArrowPosition(150);
        }
      }
    }
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [deviceType]);

  return <div className='menuStyle'>
    <div className='ScrollableMenuWrapper'>
      <Scrollable heightWidth={heightWidth} arrowPosition={arrowPosition}>
        {menu.map(item => (
          <ScrollableEntry item={item} heightWidth={heightWidth} />
        ))}
      </Scrollable>
    </div>

  </div>;
}

